<template>
  <v-text-field
    v-model="value"
    :required="isRequired"
    :class="{ 'is-required': isRequired }"
    :label="fieldName"
    pattern="[0-9]*"
    inputmode="numeric"
    type="text"
    :persistent-placeholder="true"
    :rules="validationRules"
  />
</template>

<script setup lang="ts">
import type{ PropType } from 'vue';
import { useDynamicValueGetSet } from '@/dynamic-form/composables/use-dynamic-value-get-set';
import { useIsRequired } from '@/dynamic-form/composables/use-is-required';
import type { IVisitFormInputField } from '@einfachgast/shared';
import { useExtractValidationRules } from '@/dynamic-form/composables/use-extract-validation-rules';
import { useFieldname } from '@/dynamic-form/composables/use-fieldname';

const props = defineProps({
    field: {
      type: Object as PropType<IVisitFormInputField>,
      required: true,
      default: () => ({} as IVisitFormInputField),
    },
  });
const fieldName = useFieldname(props.field);

const isRequired = useIsRequired(props.field);
const validationRules = useExtractValidationRules(props.field);
const value = useDynamicValueGetSet(props.field);
</script>